import React from "react";

const Events = () => {
  return (
    <div>
      <h1 className="text-rose-700">Branch Events</h1>
      <br />
      <br />
      <div >
        <p>January 19, 2025 - Gerneral Meeting.</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>February 23, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>March 16, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>April 27, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>May 25, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>June 22, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>September 21, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>October 19, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>November 11, 2025 - Remembrance Day</p>
        <p style={{ paddingLeft: 50 }}>
        </p>
        <br />
        <p>November 16, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <br />
        <p>December 14, 2025 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
      </div>
    </div>
  );
};

export default Events;
