import React from "react";
import CoffeePoster from "../assets/CoffeeClub.jpg";

const VetsCoffee = () => {
  return (
    <div>
      <h1 className="text-rose-700">Vets Drop-in Coffee</h1>
      <br />
      <br />
      <p>
        Join us for a <b>Veterans Coffee Drop-In!</b> Whether you’re looking to
        reconnect with fellow veterans, share stories, or just enjoy a good cup
        of coffee, this is a great opportunity to relax in a friendly and
        welcoming environment. No agenda, no pressure—just good company and
        conversation with those who understand the veteran experience. Stop by,
        grab a coffee, and connect with old friends or make new ones. We’d love
        to see you there!
      </p>
      <br />
      <br />
      <div>
        <p>April 7th - Monday</p>
        <p style={{ paddingLeft: 50 }}>
          10am at Serious Coffee (102-2417 Beacon Ave. Sidney, BC)
        </p>
        <br />
        <p>May 5th - Monday</p>
        <p style={{ paddingLeft: 50 }}>
          10am at Serious Coffee (102-2417 Beacon Ave. Sidney, BC)
        </p>
        <br />
        <p>June 2nd - Monday</p>
        <p style={{ paddingLeft: 50 }}>
          10am at Serious Coffee (102-2417 Beacon Ave. Sidney, BC)
        </p>
        <br />
      </div>
      <div className="flex content-center justify-center">
        <img src={CoffeePoster} alt="logo" style={{}} />
      </div>
    </div>
  );
};

export default VetsCoffee;
