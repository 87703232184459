import React from "react";

const Committees = () => {
  return (
    <div>
      <h1 className="text-rose-700">Committees</h1>
      <br />
      <div class="">
        <h2 className="text-rose-700">Officers for 2025:</h2>
        <p>
        </p>
        <p>
          <strong>President</strong> - Ryan Trelford
          <br />
          <strong>Past President</strong> - Mary Truttman
          <br />
          <strong>1<sup>st</sup> Vice-President</strong> - Ken Podmore
          <br />
          <strong>2<sup>nd</sup> Vice-President</strong> - Bruce Grant
          <br />
          <strong>Honorary-President</strong> - Frank Hawboldt
          <br />
          <strong>Treasurer</strong> - Kelly Lovell
          <br />
          <strong>Secretary</strong> - Michelle Anderson
          <br />
          <strong>Sergeant-At-Arms</strong> - Tyson King
          <br />
          <strong>Service Officer</strong> - Valerie Noyes
          <br />
          <strong>Padre</strong> - Eric Trovall
        </p>
      </div>

      <br />
      <div class="">
        <div>
          <h2 className="text-rose-700">Executive Committee</h2>
          <ul>
            <li>Michelle Anderson</li>
            <li>Laine Bennett</li>
            <li>Graden Dej</li>
            <li>Bruce Grant</li>
            <li>Sandy Gregoire</li>
            <li>Tyson King</li>
            <li>Kelly Lovell</li>
            <li>Jill McCormack</li>
            <li>Ken Podmore</li>
            <li>Ryan Trelford</li>
            <li>Eirc Trovall</li>
          </ul>
        </div>
      </div>
      <br />
      <div id="">
        <div class="">
          <h2 className="text-rose-700">Committees and Chairmen:</h2>
          <p>
            <strong>Finance</strong> - Kelly Lovell
            <br />
            <strong>Membership</strong> - Sandy Gregoire
            <br />
            <strong>Poppy</strong> - Laine Bennett
            <br />
            <strong>Bursary/Youth</strong> - Jill McCormack
            <br />
            <strong>Ways and Means</strong> - Mary Truttman
            <br />
            <strong>Honours and Awards</strong> - Ken Podmore
            <br />
            <strong>Zone Representative</strong> - Valerie Noyes
            <br />
            <strong>Communications</strong> - Graden Dej
            <br />
            <strong>Branch Building</strong> - Ryan Trelford
            <br />
            <strong>Entertainment and Events</strong> - TBD
            <br />
            <strong>Sick and Visiting</strong> - Valerie Noyes
            <br />
            <strong>House</strong> - TBD
            <br />
            <strong>Cadet Liason</strong> - Bruce Grant
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Committees;
