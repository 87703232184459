import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Contact from "./routes/contact";
import Services from "./routes/services";
import Committees from "./routes/committees";
import Meatdraw from "./routes/meatdraw";
import Membership from "./routes/membership";
import Bursaries from "./routes/bursaries";
import Events from "./routes/events";
import Poppy from "./routes/poppy";
import Branch from "./routes/branch";
import History from "./routes/history";
import Executive from "./routes/executive";
import DDay from "./routes/DDay";
import BBQ from "./routes/BBQ";
import VetsCoffee from "./routes/vetscoffee";

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,

      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/membership",
        element: <Membership />,
      },
      {
        path: "/Bursaries",
        element: <Bursaries />,
      },
      {
        path: "/committees",
        element: <Committees />,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/branch",
        element: <Branch />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/poppy",
        element: <Poppy />,
      },
      {
        path: "/meatdraw",
        element: <Meatdraw />,
      },
      {
        path: "/Executive",
        element: <Executive />,
      },
      {
        path: "/DDay",
        element: <DDay />,
      },
      {
        path: "/BBQ",
        element: <BBQ />,
      },
      {
        path: "/VetsCoffee",
        element: <VetsCoffee />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
